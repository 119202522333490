import { PageTitle, useScreenType } from '@orus.eu/pharaoh'
import { HealthInsuranceCPMSPage } from '@orus.eu/pharaoh/src/components/features/health-cover'
import cpmsDesktop from './cpms-desktop.png'
import cpmsMobile from './cpms-mobile.png'

export default function HealthInsuranceCpmsPage(): JSX.Element {
  const screenType = useScreenType()
  return (
    <>
      <PageTitle title="MyCPMS" fullWidth />
      <HealthInsuranceCPMSPage
        text="Consulter le suivi de vos remboursements, demander votre carte de tiers payant et l’ensemble des services pour vos soins santé depuis une seule application."
        src={screenType === 'mobile' ? cpmsMobile : cpmsDesktop}
        href="https://www.cpms.fr/connexion-particulier/"
        persistentNotificationText="Connectez-vous à l’application MyCPMS permettant de gérer votre suivi santé."
        buttonText="Accéder à MyCPMS"
      />
    </>
  )
}
